@import "../../../assets/scss/partials/variables";
.main-search {
  margin-left: 5px;
  .input-group-text {
    line-height: 1;
  }
  .input-group {
    border-radius: 20px;
    padding: 0;

    .form-control,
    .input-group-text {
      background: transparent;
      border: none;
      border-radius: 0;
      padding: 0;
    }

    .search-close {
      display: none;
    }

    .search-btn {
      border-radius: 50%;
      padding: 0;
      background: transparent;
      border-color: transparent;
      box-shadow: none;
    }

    .form-control {
      transition: all 0.3s ease-in-out;
      width: 0;
      font-size: 14px;
    }

    .form-control,
    .search-btn {
      &:active,
      &:focus,
      &:hover {
        outline: none;
        box-shadow: none;
      }
    }
  }

  &.open {
    .input-group {
      background: #fff;
      border: 1px solid #f1f1f1;
      box-shadow: 0 6px 12px -5px rgba(65,76,78,0.08);
      padding: 5px 5px 5px 20px;

      .search-close {
        display: flex;
      }

      .search-btn {
        padding: 5px;
        margin-left: 5px;
        background: $primary-color;
        border-color: $primary-color;

        .input-group-text {
          color: #fff;
        }
      }

      .form-control {
        transition: all 0.3s ease-in-out;
        width: 0;
      }

      .form-control,
      .search-btn {
        &:active,
        &:focus,
        &:hover {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}