.tutor-mapping {
  margin-bottom: 20px;

  h5 {
    margin-bottom: 20px;
  }

  .ReactTags__tagInput {
    display: inline-block;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 6px 12px -5px rgba(65, 76, 78, 0.08);
    padding: 5px 10px;
    position: relative;

    input {
      background: transparent;
      border: none;
      border-radius: 0;
      padding: 0;
    }
  }

  .ReactTags__tag {
    display: inline-block;
    margin-right: 10px;
    padding: 5px 10px;
    border: 1px solid #f1f1f1;

    a.ReactTags__remove {
      padding-left: 5px;
    }
  }

  .ReactTags__suggestions {
    position: absolute;
    background-color: white;
    top: 32px;
    border: 1px solid #f1f1f1;
    width: 100%;
    left: 0px;
    z-index: 100;

    ul {
      list-style-type: none;
      margin-left: 0;
      padding-left: 0;
      li {
        width: 100%;
        padding: 10px;
        margin: 0;
        &:hover {
          padding: 10px;
          margin: 0;
        }
      }
      li.ReactTags__activeSuggestion {
        background-color: wheat;
      }
    }
  }


}
