table.table {
  width: 100%;
  display: block;
  overflow-x: auto;
}
@media (min-width: 1800px) {
  table.table {
    display: table;
  }
}

.isSortable:hover, .isCurrentSort {
  border-color: #7a7a7a !important;
}

.tableIcon {
  margin-left: .5rem;
}

.currentPage {
  color: #2e323a !important;
  border-color: #4a4a4a !important;
  background-color: #fff;
}

.level {
  padding: 20px;
}

ul.pagination-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.table button.label, .table a.label{
  border: none;
  border-radius: 15px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}
span.icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
  .is-small {
    height: 1rem;
    width: 1rem;
  }
}

