footer {
  border-top: 1px solid #f1f2f2;
  padding: .495rem 1.5rem;
}

.level {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .level span {
    display: none;
  }
  .levelRight {
    margin-top: 0 !important;
  }
}